// var exports = {}
import "@hotwired/turbo-rails"
import "./controllers"
import "@github/relative-time-element"
import "tom-select"
import "@fortawesome/fontawesome-free/js/all"
import "trix"
import "@rails/actiontext"
import AOS from 'aos'
import 'aos/dist/aos.css'
import * as ActiveStorage from "@rails/activestorage"
import { PowerGlitch } from 'powerglitch';

ActiveStorage.start()

function glitch() {
  PowerGlitch.glitch('.glitch',
    {
      "timing": {
        "duration": 5000
      },
      "glitchTimeSpan": {
        "start": 0.61
      },
      "shake": {
        "velocity": 18
      },
      "slice": {
        "maxHeight": 1
      }
    }
  )
}

document.addEventListener('DOMContentLoaded', function() {
  AOS.init({ mirror: true })
  glitch()
})

document.addEventListener('turbo:load', () => {
  glitch()
})
